import React from "react";

export const BusinessPlan = () => (
  <>
    <div className="hero">
      <h1>Business Plan</h1>
    </div>
    <section className="hero is-fullheight mb-5">
      <div className="columns">
        <div className="column is-one-quarter fadeLeftMini"></div>
        <div className="column fadeLeftMini">
          <p>
            Welcome to the business plan for{" "}
            <span className="fire">mobilemintcarcare.com</span>, a mobile car
            valeting service dedicated to sustainability. We provide
            top-quality, convenient, and eco-friendly car cleaning and polishing
            services throughout London. Our mission is to revolutionize the car
            washing industry with our innovative, efficient, and cost-effective
            waterless cleaning service.
          </p>
          <p>
            Our strategy includes equipping our mobile units with the latest
            eco-friendly technology and recruiting a skilled team committed to
            exceptional customer service and environmental care. Our services
            range from colour and clarity restoration to waxing, tyre pressure
            checks, and screen wash top-ups, all designed with sustainability in
            mind. We tailor our services to meet the unique needs of our
            customers, ensuring a premium eco-friendly experience.
          </p>
          <p>
            This business plan outlines our mission, objectives, strategies,
            market analysis, sales and marketing plans, operational plans, and
            financial forecasts. Our unique approach and commitment to customer
            satisfaction and environmental stewardship set us apart in the
            marketplace. We are excited to share our vision and, with your
            support, make <span className="fire">mobilemintcarcare.com</span> a
            success as we move towards a more sustainable future.
          </p>
          <button
            className="button is-pulled-right btn"
            title="Business Plan"
            onClick={(event) => (window.location.href = "/BusinessExecutive")}
          >
            <span>Business Plan</span>
          </button>
        </div>
        <div className="column is-one-quarter fadeLeftMini"></div>
      </div>
    </section>
  </>
);
