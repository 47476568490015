import React from "react";

export const BenefitsAdvantages = () => (
  <>
    <div className="hero">
      <h1>Benefit & Advantages</h1>
    </div>
    <section className="hero is-fullheight mb-5">
      <div className="columns">
        <div className="column fadeLeftMini">
          <h2>Customer</h2>
          <h6>Convenience:</h6>
          <p>
            Enjoy the convenience of having your car cleaned and polished
            wherever you are, eliminating the need to visit a traditional car
            wash or detailing service.
          </p>
          <h6>Time-saving:</h6>
          <p>
            Our mobile service saves you time by coming to you, so you don't
            have to travel or wait for your car to be cleaned.
          </p>
          <h6>High-quality service:</h6>
          <p>
            We use advanced hybrid SiO2 technology and hybrid ceramic liquid wax
            to ensure high-quality polishing and waxing, restoring color and
            clarity while removing paint defects, oxidation, swirls, fine
            scratches, water spots, and blemishes.
          </p>
          <h6>Water-saving:</h6>
          <p>
            Our waterless cleaning method conserves more water than traditional
            techniques, promoting environmental sustainability.
          </p>
          <h6>Additional features:</h6>
          <p>
            We offer extra services like tyre pressure checks and screen wash
            top-ups, adding value and convenience for our customers.
          </p>
          <h6>Competitive pricing:</h6>
          <p>
            Our high-quality service is offered at competitive prices, making it
            affordable and convenient to keep your car pristine.
          </p>
          <h6>Professionalism:</h6>
          <p>
            Our professional and well-presented mobile service instills
            confidence in the quality of service you will receive.
          </p>
          <h6>Flexibility:</h6>
          <p>
            Designed to fit around your busy schedule, our flexible service
            enhances overall customer satisfaction.
          </p>
        </div>
        <div className="column fadeRightMini">
          <h2>Investor</h2>
          <h6>Promising Future:</h6>
          <p>
            Our mobile car cleaning and polishing service is based on a proven
            concept with growing demand, ensuring a promising future.
          </p>
          <h6>High Demand:</h6>
          <p>
            The consistent demand for car cleaning services, coupled with the
            convenience of our mobile model, increases customer demand.
          </p>
          <h6>Scalability:</h6>
          <p>
            Our scalable business model offers opportunities for expansion and
            increased return on investment.
          </p>
          <h6>Low Overhead:</h6>
          <p>
            Lower overhead costs compared to traditional car washes lead to
            higher profit margins for investors.
          </p>
          <h6>Eco-Friendly Approach:</h6>
          <p>
            Our waterless service is both eco-friendly and cost-efficient,
            aligning with consumer demand for environmentally friendly services.
          </p>
          <h6>Potential for Franchising:</h6>
          <p>
            Our business model is ideal for franchising, providing investors
            with a potential stream of passive income as we expand.
          </p>
          <h6>High-Profit Margin:</h6>
          <p>
            Our mobile service typically offers high-profit margins due to low
            overhead and high demand for convenience.
          </p>
          <h6>Competitive Pricing:</h6>
          <p>
            Our competitive pricing for high-quality services attracts and
            retains customers, increasing profit margins and return on
            investment.
          </p>
        </div>
      </div>
    </section>
  </>
);
