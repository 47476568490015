import React, { useState, useEffect } from "react";

const PasswordProtected = ({ password, children }) => {
  const [enteredPassword, setEnteredPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const authenticated = localStorage.getItem("authenticated");
    if (authenticated) {
      setIsAuthenticated(true);
    }
  }, []);

  const handlePasswordChange = (e) => {
    setEnteredPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (enteredPassword === password) {
      setIsAuthenticated(true);
      localStorage.setItem("authenticated", "true");
    } else {
      alert("Incorrect password. Please try again.");
    }
  };

  return (
    <div>
      {isAuthenticated ? (
        children
      ) : (
        <div>
          <h2>Enter Password</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="password"
              value={enteredPassword}
              onChange={handlePasswordChange}
              placeholder="Enter password"
            />
            <button type="submit">Submit</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default PasswordProtected;
