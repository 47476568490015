import React from "react";
import logoM from "./assets/mobilemintcarcare-logo.svg";

export const Home = () => (
  <>
    <section className="hero is-fullheight-with-navbar mb-5">
      <div className="columns is-vcentered">
        <div className="column fadeLeftMini">
          <img
            src={logoM}
            alt="Mobile Mint Car Care Logo"
            style={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              opacity: "100%",
            }}
          />
        </div>
        <div className="column fadeRightMini">
          <h2>Introduction</h2>
          <p>
            Welcome to{" "}
            <span className="fire">MMCC® | Mobile Mint Car Care</span>, your
            premier mobile waterless car cleaning and valeting service. We are
            transforming the £1.7 billion (2022) car cleaning industry with our
            eco-friendly, time-saving solutions.
          </p>
          <p>
            Our commitment to sustainability means we use only the best
            eco-friendly products and equipment. Our comprehensive valet service
            starts at £40, and our premium service, featuring compound clay,
            colour, polish, and wax, starts at £100.
          </p>
          <p>
            Our mission is to lead the UK's mobile car cleaning sector,
            promoting a sustainable future in automotive care. We aim to expand
            globally through franchising, spreading our eco-conscious approach
            worldwide. Our strategies include:
          </p>
          <ul>
            <li>A highly skilled and professional workforce.</li>
            <li>Strict quality control measures.</li>
            <li>
              Commitment to green practices for continuous customer
              satisfaction.
            </li>
          </ul>
          <p>
            With our ambitious goals and investor support, we are confident in
            delivering strong returns. Our service is essential for a
            sustainable, eco-friendly future.
          </p>
          <p>
            <span className="fire">MMCC</span>® is a registered brand of{" "}
            <span className="fire">Mobile Mint Car Care</span>.
          </p>
        </div>
      </div>
    </section>
  </>
);
