import React from "react";
import "./components/navigationbar.css";
import { Tab, Tabs } from "react-bootstrap";

export const BusinessExecutive = () => (
  <>
    <div className="hero">
      <h1>Business Plan</h1>
    </div>
    <section className="hero is-fullheight mb-5">
      <div className="columns">
        <div className="column is-one-fifth fadeLeftMini"></div>
        <div className="column is-vcentered">
          <Tabs defaultActiveKey="executive" id="uncontrolled-tab-example">
            <Tab eventKey="executive" title="Executive Plan">
              <p>
                <span className="fire">MMCC®</span> is a pioneering mobile car
                cleaning service providing environmentally friendly solutions to
                the £1.7 billion (2022) car cleaning market in the United
                Kingdom. Our unique selling point is our waterless cleaning
                system, which utilises a mere 5 litres of water per car,
                significantly less than traditional methods that can consume up
                to 150 litres. Our services encompass full valet and compound
                clay, colour, polish, and wax, all competitively priced,
                starting at £40 and £100 respectively.
              </p>
              <p>
                Our ambition is to emerge as a leading mobile car cleaning
                service in the UK, targeting a revenue of £10 million by 2026 by
                launching 50 franchises nationwide. We strive to achieve this
                through the provision of uniform, high-quality service, and
                comprehensive training and support for our franchisees. Our
                strategy also includes a focus on global expansion through
                franchising.
              </p>
              <p>
                We plan to retain a 15% share per car from our franchisees,
                which will be allocated to covering costs including tools,
                insurance, and training, among others. Our business model is
                scalable, and our aim is to build a robust brand reputation for{" "}
                <span className="fire">MMCC®</span>, to attract more customers
                and franchisees in the future.
              </p>
              <p>
                <h6>Specific</h6>

                <li>
                  Our objectives outlined in the executive summary are specific
                  to the mobile car cleaning industry, aiming to boost revenue
                  and expand the business through franchising.
                </li>
              </p>
              <p>
                <h6>Measurable</h6>

                <li>
                  The objectives include definitive targets for revenue, the
                  number of franchises, and market share.
                </li>
              </p>
              <p>
                <h6>Achievable</h6>
                <li>
                  These objectives are achievable through the application of a
                  proven business model, effective marketing strategies, and
                  robust franchise support.
                </li>
              </p>
              <p>
                <h6>Relevant</h6>
                <li>
                  The objectives are pertinent to the overall success and growth
                  of the business and align with the mission and objectives of{" "}
                  <span className="fire">MMCC®</span>
                </li>
              </p>
              <p>
                <h6>Time-bound</h6>
                <li>
                  The objectives include specific timelines for achieving
                  targets, such as reaching a certain number of franchises
                  within the first five years of operation.
                </li>
              </p>
              <p>
                <h6>Strategies</h6>
                <li>
                  Developing a comprehensive training programme for all
                  franchisees to ensure uniform and high-quality service
                  delivery.
                </li>
                <li>
                  Establishing a strong online presence through a user-friendly
                  website, social media, and digital marketing campaigns.
                </li>
                <li>
                  Implementing eco-friendly practices and promoting the use of
                  waterless cleaning solutions to appeal to environmentally
                  conscious customers.
                </li>
                <li>
                  Regularly gathering customer feedback and using it to improve
                  and innovate our services.
                </li>
                <li>
                  Expanding the range of services offered to include detailing,
                  paint protection, and ceramic coating.
                </li>
                <li>
                  Developing partnerships with car dealerships and auto repair
                  shops to offer mobile car cleaning services to their
                  customers.
                </li>
                <li>
                  Investing in research and development to stay abreast of
                  industry trends and enhance our service offerings.
                </li>
                <li>
                  Continually monitoring and benchmarking against competitors to
                  ensure our pricing and service offerings remain competitive.
                </li>
                <li>
                  Offering loyalty programmes and regular promotions to
                  encourage repeat business and customer retention.
                </li>
                <li>
                  Regularly evaluating and optimising our franchise model to
                  ensure strong financial performance for franchisees.
                </li>
              </p>
            </Tab>

            <Tab eventKey="company" title="Company Description">
              <p>
                <span className="fire">MMCC®</span> will be a privately held
                limited liability company specialising in environmentally
                friendly mobile car cleaning services. Our mission will be to
                deliver top-tier car cleaning services that not only rejuvenate
                our customers' cars to their original shine but also contribute
                to the betterment of the environment.
              </p>
              <p>
                Initially, the company will be owned and operated by the
                founder, who will possess 51% of the shares. The remaining 49%
                of shares will be open for investment opportunities, including
                franchising.
              </p>
              <p>
                Our services will cover comprehensive valet and detailing,
                encompassing exterior and interior cleaning, polishing, and
                waxing, in addition to specialised services such as compound
                clay and colour correction. We will commit ourselves to using
                only eco-friendly and waterless cleaning solutions and methods,
                thereby substantially reducing water usage and waste, reflecting
                our dedication to green practices.
              </p>
              <p>
                Our growth strategy will involve expanding our business through
                franchising, both within the UK and globally, with a target of
                establishing 50 franchise locations over the next 5 years. This
                expansion will not only increase our revenue but also create job
                opportunities and bolster local communities, providing a
                positive socio-economic impact.
              </p>
              <p>
                We aim to expand our business through franchising, both locally
                and globally, with the goal of having 50 franchise locations
                within the next 5 years. This will not only increase our
                revenue, but also provide job opportunities and support for
                local communities.
              </p>
              <p>
                With our distinctive business model, efficient operations, and
                resolute commitment to eco-friendly solutions,{" "}
                <span className="fire">MMCC®</span> is poised to make a
                significant impact in the car cleaning industry, paving the way
                for a more sustainable future.
              </p>
            </Tab>

            <Tab eventKey="industry" title="Industry Analysis">
              <p>
                The mobile car cleaning industry is a burgeoning sector,
                estimated to be worth £1.7 billion in the United Kingdom. A
                significant increase in demand has been observed in recent years
                due to a confluence of factors such as rising disposable income,
                growing environmental consciousness of the advantages of mobile
                car cleaning, and the convenience and flexibility provided by
                mobile services.
              </p>
              <p>
                The industry is characterised by a diverse range of players,
                spanning small, independent operators to large, national
                franchises. Despite the market's competitiveness, with numerous
                companies vying for a slice of the market share, there remains
                ample room for new entrants. The industry is relatively
                fragmented, and the quality of service offered by different
                companies varies significantly.
              </p>
              <p>
                One of the key developments in the mobile car cleaning industry
                is the shift towards eco-friendly solutions. An increasing
                number of consumers are cognizant of their environmental
                footprint and are seeking companies that utilise environmentally
                friendly cleaning products and methods. This trend presents an
                opportunity for <span className="fire">MMCC®</span> to
                distinguish itself by offering eco-friendly cleaning services
                and emphasising its commitment to sustainability.
              </p>
              <p>
                Another notable trend is the industry's focus on high-end,
                premium services. A substantial segment of consumers is willing
                to pay a premium for top-quality, professional cleaning
                services. This trend provides an opportunity for{" "}
                <span className="fire">MMCC®</span> to set itself apart by
                offering high-end, premium cleaning services, catering to a more
                affluent clientele.
              </p>
              <p>
                In terms of market share, the dominant players in the mobile car
                cleaning industry are currently national franchises, commanding
                around 15% of the market. Independent operators account for
                approximately 20% of the market, with the remaining 65% split
                among smaller regional players.
              </p>
              <p>
                Overall, the mobile car cleaning industry offers a significant
                opportunity for <span className="fire">MMCC®</span> to penetrate
                the market and secure a share of the escalating demand for
                mobile car cleaning services. By concentrating on eco-friendly
                solutions and high-end, premium services, MMCC® can
                differentiate itself from competitors and establish itself as a
                forerunner in the industry.
              </p>
            </Tab>
            <Tab eventKey="market" title="Market Analysis">
              <p>
                The car cleaning industry in the United Kingdom is currently
                valued at an estimated £1.7 billion, with steady growth
                projected in the years to come. The industry encompasses a
                diverse range of small, independent operators as well as large,
                established companies, offering services from basic car washing
                to comprehensive detailing.
              </p>
              <p>
                <span className="fire">MMCC®</span> intends to cater to the
                high-end car cleaning market, an area currently underserved in
                the UK. Our unique selling point is our
                environmentally-conscious cleaning solutions, which will
                differentiate us from traditional car cleaning services.
              </p>
              <p>
                <h6>Target Market</h6>
                <p>
                  Our target market includes car owners and businesses willing
                  to pay a premium for high-quality, eco-friendly car cleaning
                  services. Our target market includes:
                </p>
                <li>
                  <b>High-income individuals</b>: Our premium pricing strategy
                  will appeal to high-income individuals who value quality and
                  are willing to pay more for environmentally-friendly services.
                </li>
                <li>
                  <b>Luxury car owners</b>: Our services will be particularly
                  attractive to luxury car owners who want to maintain the
                  appearance and value of their vehicles.
                </li>
                <li>
                  <b>Environmentalists</b>: Our eco-friendly cleaning solutions
                  will appeal to environmentally-conscious customers who want to
                  reduce their carbon footprint.
                </li>
                <li>
                  <b>Fleet Owners</b>: Businesses with sizable vehicle fleets
                  who are conscious of their environmental footprint and value
                  the convenience of our mobile service will form a significant
                  part of our target market. These could include car rental
                  companies, taxi services, logistics companies, and others that
                  maintain large fleets of vehicles and are looking to partner
                  with eco-friendly service providers.
                </li>
              </p>
              <p>
                By addressing the needs of these varied groups, we'll be
                well-positioned to carve out a significant presence in the UK's
                car cleaning industry. Our emphasis on eco-friendly practices,
                combined with a convenient and high-quality service, will make
                us a favoured choice for customers and businesses alike.
              </p>
              <p>
                <h6>Market Segmentation</h6>
                <p>
                  Our market segmentation will be based on the following
                  criteria:
                </p>
                <li>
                  <b>Geography</b>: We will initially focus on major cities in
                  the UK, such as London, Manchester, and Birmingham. As we
                  expand, we will target other cities and towns across the
                  country.
                </li>
                <li>
                  <b>Demographics</b>: Our target market is primarily composed
                  of high-income individuals, luxury car owners,
                  environmentalists, and fleet owners who prioritise
                  eco-friendly services.
                </li>
                <li>
                  <b>Psychographics</b>: Our target market values quality,
                  luxury, environmentally-friendly products and services, and
                  the convenience of mobile service.
                </li>
                <li>
                  <b>Business Sector</b>: We also target businesses that
                  maintain large vehicle fleets, particularly those with a
                  commitment to reducing their environmental footprint.
                </li>
              </p>
              <p>
                By addressing the needs of these varied segments, we can tailor
                our services to meet their unique requirements, which will
                position us strongly in the UK car cleaning market.
              </p>
              <p>
                <h6>Market Needs</h6>
                <p>
                  The market needs that <span className="fire">MMCC®</span> is
                  poised to address are dynamic and diverse, showcasing the
                  abundant potential of our business model:
                </p>
                <li>
                  <b>Filling the Eco-Friendly Void</b>: There is a glaring lack
                  of eco-friendly car cleaning services in the UK. As
                  environmental consciousness becomes a priority for individuals
                  and businesses alike, MMCC® provides a powerful and timely
                  solution, utilising innovative, water-saving techniques that
                  drastically reduce the environmental footprint of car
                  cleaning.
                </li>
                <li>
                  <b>Premium Quality Services</b>: There is a growing demand for
                  high-quality, professional car cleaning services that cater
                  not just to individual luxury car owners, but also to
                  businesses with large vehicle fleets. We provide an
                  unparalleled premium service that not only maintains the
                  aesthetics of vehicles but also helps preserve their value,
                  contributing to customer satisfaction and vehicle longevity.
                </li>
                <li>
                  <b>Sustainable Commitment for Businesses</b>: Companies with
                  large vehicle fleets are seeking service providers who align
                  with their own environmental commitments. Our eco-friendly car
                  cleaning approach allows these businesses to maintain their
                  fleet responsibly, without compromising on their
                  sustainability goals.
                </li>
                <li>
                  <b>Unrivalled Convenience</b>: In today's fast-paced world,
                  convenience is key. Our mobile service brings professional car
                  cleaning to our customers' doorstep, be it at home, work, or
                  any other chosen location. This distinctive feature saves time
                  for our customers, adding an invaluable layer of convenience
                  to their busy lives.
                </li>
              </p>

              <p>
                The unique blend of these market needs presents a compelling
                investment opportunity. MMCC® is perfectly positioned to tap
                into these needs, offering an eco-friendly, high-quality,
                convenient solution that is not only profitable but also
                contributes positively to the environment and society. We firmly
                believe that with the right investment, we can drive significant
                growth, delivering robust returns while making a difference in
                the car cleaning industry.
              </p>

              <p>
                <h6>Emerging Market Trends</h6>
                <p>
                  The UK car cleaning industry is experiencing a transformative
                  shift, propelled by several key trends:
                </p>
                <li>
                  <b>Surge in demand for eco-friendly solutions</b>: As the
                  world grapples with the daunting realities of climate change,
                  consumers are increasingly mindful of their environmental
                  footprint. This has ignited a growing demand for car cleaning
                  services that champion eco-friendly solutions.
                  MobileMintCarCare.com, with its pioneering water-saving
                  technology that uses a mere 5 litres per clean, is poised to
                  lead this green revolution in the car cleaning industry,
                  offering customers an outstanding service that also aligns
                  with their values.
                </li>
                <li>
                  <b>Rising need for convenience</b>: In today's fast-paced
                  world, convenience has become a currency. Customers are
                  actively seeking out services that fit seamlessly into their
                  busy schedules. Our mobile service caters to this very need,
                  offering premium car cleaning services at the customer's
                  chosen location. This unique selling point not only sets us
                  apart from competitors but also positions us as a preferred
                  choice for customers valuing time and convenience.
                </li>
                <li>
                  <b>Growing appetite for luxury services</b>: The UK car
                  cleaning market is witnessing an uptick in demand for high-end
                  services. Luxury car owners are increasingly willing to pay a
                  premium to maintain the pristine appearance and value of their
                  vehicles. <span className="fire">MMCC®</span> is poised to
                  cater to this demand with our range of high-quality services
                  tailored specifically for the luxury car owner segment.
                </li>
              </p>
              <p>
                These trends underscore the vast potential in the car cleaning
                industry and present a compelling opportunity for investors.
                With our strong commitment to eco-friendliness, emphasis on
                customer convenience, and a focus on high-end services,{" "}
                <span className="fire">MMCC®</span> is ideally positioned to
                capture a significant share of this burgeoning market.
              </p>
              <p>
                <h6>Competitive Landscape</h6>
                <p>
                  The mobile car cleaning industry is teeming with a variety of
                  competitors, both small independent operators and large,
                  well-established companies. Some of the prominent players in
                  this market include CARCLENX, NuWash, Mobile Car Wash, Get
                  Spiffy, Inc., Doorstepwash, Washe, Immaculate Car detailing,
                  Volvo Car Mobile Wash, Detailking Group, and Wash Tub Car
                  Wash, among others.
                </p>
                <p>
                  Despite the presence of these established players, the
                  industry is still relatively new, making waves internationally
                  as it combines quality and convenience, making it a popular
                  choice for customers. While rollover/in-bay car washes
                  currently hold the largest market share, mobile car wash
                  businesses show significant potential. The demand for
                  water-efficient products is driving growth in this segment,
                  and by offering a mobile water-conscious car wash, a business
                  can tap into this demand. Offering a personalized and
                  memorable service can also give a business an edge over its
                  competitors in this industry.
                </p>
                <p>
                  The industry is seeing a shift from DIY car washing to a "do
                  it for me" preference, indicating growth in the on-demand car
                  wash sector. A mobile car wash is well-suited to offer
                  on-demand services, made more accessible via smartphone apps,
                  which also reduces administrative work for business owners and
                  adds convenience for customers.
                </p>
                <p>
                  Looking forward, the mobile car wash industry is projected to
                  reach $22.9 billion by the end of 2032. Incorporating the
                  latest tools and technology, such as steam cleaning vehicles,
                  can help a business stand out. Such methods are eco-friendly,
                  aligning with customers' increasing care for sustainability
                  and willingness to pay more for it.
                </p>
                <p>
                  Moreover, the mobile car wash industry is popular because it
                  is not restricted to a physical location or fixed opening
                  hours. This flexibility appeals to the modern customer who is
                  busier than ever and can result in increased profits for
                  businesses that extend their working hours and serve more
                  customers per day.
                </p>
                <p>
                  In terms of marketing, businesses in this industry can reach
                  customers through their smartphones with targeted marketing
                  campaigns highlighting the convenience of a mobile car wash.
                  Social media marketing on platforms like Facebook and
                  Instagram, as well as search engine optimization (SEO)
                  strategies, can boost a business's online visibility.
                </p>
                <p>
                  To successfully enter this dynamic and competitive market, a
                  business must demonstrate that it can stand out and provide
                  exceptional service. The growing demand for convenience and
                  trending eco-friendly cleaning methods present a lucrative
                  opportunity for new entrants.
                </p>
              </p>
            </Tab>
            <Tab eventKey="sales" title="Marketing and Sales Plan">
              <p>
                <h6>Market Analysis</h6>

                <li>
                  The mobile car cleaning industry is a rapidly growing market,
                  showing a promising trajectory. As of 2023, the mobile car
                  wash industry in the US alone, is projected to reach $22.9
                  billion by 2032.
                </li>
              </p>
              <p>
                <h6>Target Market</h6>
                <li>
                  The primary target market of{" "}
                  <span className="fire">MMCC®</span> includes individuals and
                  businesses that prioritize convenience and eco-friendly
                  services. This encompasses a broad customer base, from busy
                  professionals to small businesses, large corporations, and
                  consumers passionate about environmental sustainability. The
                  service is highly appealing as it allows customers to schedule
                  car washes at their locations, such as homes, workplaces,
                  parking lots, and shopping malls. Other potential target
                  markets include companies needing commercial fleet services
                  and niche sectors like caravan owners, boat owners, and
                  aircraft detailing.
                </li>
              </p>
              <p>
                <h6>Marketing Strategy</h6>
                <li>
                  <b>Online marketing</b>: Utilizing various online marketing
                  techniques such as Search Engine Optimization (SEO),
                  Pay-Per-Click (PPC) advertising, social media marketing, and
                  email marketing can effectively reach the target market.
                  Digital marketing trends in the automotive industry, like
                  maximizing the use of social media marketing and SEO
                  strategies, can significantly boost the online visibility of
                  the business.
                </li>
                <li>
                  <b>Referral marketing</b>: Incentivizing existing customers
                  through referral programs and discounts can increase the
                  customer base through word-of-mouth marketing.
                </li>
                <li>
                  <b>Local marketing</b>: Targeted advertising and promotions in
                  specific service areas can efficiently attract local
                  customers.
                </li>
                <li>
                  <b>Public relations</b>: Establishing relationships with key
                  influencers and media outlets can generate positive press
                  coverage and enhance the brand's reputation.
                </li>
              </p>
              <p>
                <h6>Sales Strategy:</h6>
                <li>
                  <b>Direct sales</b>: Employing direct sales approaches,
                  including cold calling, door-to-door sales, and networking
                  events, can reach potential customers effectively.
                </li>
                <li>
                  <b>Inbound sales</b>: Using inbound sales techniques to
                  convert website visitors into customers, such as lead magnets,
                  landing pages, and email marketing, can significantly increase
                  conversions.
                </li>
                <li>
                  <b>Upselling and cross-selling</b>: Implementing upselling and
                  cross-selling techniques to enhance the value of each
                  transaction can boost revenues. This could include offering
                  additional services or package deals.
                </li>
              </p>
              <p>
                <h6>Sales Forecast</h6>
                <li>
                  Based on the industry's growth rate and the comprehensive
                  sales strategies, it is reasonable to expect robust revenue
                  growth. While an exact figure would require more specific
                  data, a focus on providing high-quality, eco-friendly, and
                  convenient services should drive business growth.
                </li>
              </p>
              <p>
                <h6>Pricing Strategy:</h6>
                <li>
                  A value-based pricing strategy will be optimal for{" "}
                  <span className="fire">MMCC®</span>, enabling it to charge a
                  premium for its high-quality, eco-friendly, and convenient
                  services. It is important to ensure that pricing aligns with
                  the perceived value of the services, so pricing will be
                  determined based on the value it provides to customers.
                </li>
              </p>
              <p>
                <h6>Promotion:</h6>
                <li>
                  An effective promotional strategy will include a mix of online
                  and offline promotions to drive sales and generate awareness
                  for <span className="fire">MMCC®</span>. This can include
                  discounts for new customers, referral programs, and seasonal
                  promotions, all designed to attract and retain customers while
                  enhancing the brand's visibility.
                </li>
              </p>
            </Tab>
            <Tab eventKey="operational" title="Operational Plan">
              <p>
                <h6>Staffing:</h6>
                <p>
                  To ensure the highest quality of service, we will recruit
                  highly skilled and experienced car detailing professionals. In
                  addition, we will invest in continuous training and
                  development opportunities for our staff, keeping them abreast
                  of the latest techniques and industry advancements.
                </p>
              </p>
              <p>
                <h6>Equipment and Supplies:</h6>
                <p>
                  Our commitment to top-tier service means investing in
                  industry-leading equipment and supplies. This includes
                  eco-friendly cleaning solutions, reflecting our commitment to
                  sustainable practices. Regular maintenance and timely
                  replacement of equipment will ensure optimal performance. Some
                  of the key equipment and supplies we will require are:
                </p>
                <li>Waterless cleaning solution</li>
                <li>
                  Advanced hybrid SiO2 technology for polishing and waxing
                </li>
                <li>Tyre pressure gauge</li>
                <li>Screen Wash</li>
                <li>Microfiber towels</li>
                <li>Detailing brushes</li>
                <li>Wax applicator pads</li>
                <li>Buffing machines</li>
                <li>Mobile power supply</li>
                <li>Portable water tanks</li>
                <li>GPS tracking devices</li>
                <li>Work uniforms</li>
                <li>Business cards and brochures</li>
              </p>
              <p>
                <h6>Scheduling and Appointments:</h6>
                <p>
                  We will leverage a state-of-the-art scheduling system to
                  manage appointments, ensuring timely and efficient service for
                  all our customers. This aligns with the market trend of
                  on-demand car wash services, making scheduling a car wash and
                  processing payments effortless.
                </p>
              </p>

              <p>
                <h6>Quality Control:</h6>
                <p>
                  We are committed to maintaining high standards in all our
                  services. To this end, we will implement stringent quality
                  control measures, including regular inspections and customer
                  feedback surveys, to ensure we continually meet and exceed
                  expectations.
                </p>
              </p>

              <p>
                <h6>Health and Safety:</h6>
                <p>
                  We prioritize the safety of our staff and customers. We will
                  strictly adhere to all relevant health and safety regulations
                  and guidelines to ensure a safe working environment at all
                  times.
                </p>
              </p>
              <p>
                <h6>Expansion:</h6>
                <p>
                  To reach more customers and increase revenue, we will consider
                  expanding our services to new locations through franchising.
                  This aligns with the flexible business model inherent in the
                  mobile car wash industry.
                </p>
              </p>
              <p>
                <h6>Continual Improvements:</h6>
                <p>
                  We are dedicated to continuous improvement. Regular reviews
                  and evaluations of our operations will help us identify areas
                  for enhancement. Implementing changes as necessary will ensure
                  we continually improve efficiency and customer satisfaction.
                  This commitment to exceptional service and constant
                  improvement will help us stand out in a competitive market.
                </p>
              </p>
            </Tab>
            <Tab eventKey="financial" title="Financial Projections">
              <p>
                <h6>Income Statement:</h6>
                <p>Year 1:</p>
                <li>Sales Revenue: £150,000</li>
                <li>
                  Franchise Sales: 3 franchises * £10,000/franchise = £30,000
                </li>
                <li>Total Revenue: £180,000</li>
                <li>
                  COGS (Cost of Goods Sold): 35% of Sales Revenue = £52,500
                </li>
                <li>Gross Profit: Total Revenue - COGS = £127,500</li>
                <li>Operating Expenses: 15% of Gross Profit = £19,125</li>
                <li>
                  Net Income Before Taxes: Gross Profit - Operating Expenses =
                  £108,375
                </li>
                <li>Taxes (26.5% corporate tax rate): £28,719.37</li>
                <li>Net Income After Taxes: £79,655.63</li>
              </p>
              <p>
                <p>Year 2:</p>
                <li>Sales Revenue: £150,000 * 15% = £172,500</li>
                <li>
                  Franchise Sales: 5 franchises * £10,000/franchise = £50,000
                </li>
                <li>Total Revenue: £222,500</li>
                <li>
                  COGS (Assuming a 2% rise due to inflation or other factors):
                  37% of Sales Revenue = £63,825
                </li>
                <li>Gross Profit: Total Revenue - COGS = £158.675</li>
                <li>
                  Operating Expenses (Assuming a 5% increase due to business
                  expansion): 15.75% of Gross Profit = £24,991.69
                </li>
                <li>
                  Net Income Before Taxes: Gross Profit - Operating Expenses =
                  £133,683.31
                </li>
                <li>Taxes (26.5% corporate tax rate): £33,420.82</li>
                <li>Net Income After Taxes: £100,262.49</li>
              </p>
              <p>
                <p>Year 3:</p>
                <li>Sales Revenue: £172,500 * 15% = £198,375</li>
                <li>
                  Franchise Sales: 7 franchises * £10,000/franchise = £70,000
                </li>
                <li>Total Revenue: £268,375</li>
                <li>
                  COGS (Assuming a 2% rise due to inflation or other factors):
                  39% of Sales Revenue = £77,366.25
                </li>
                <li>Gross Profit: Total Revenue - COGS = £191,008.75</li>
                <li>
                  Operating Expenses (Assuming a 5% increase due to business
                  expansion): 16.5% of Gross Profit = £31,516.44
                </li>
                <li>
                  Net Income Before Taxes: Gross Profit - Operating Expenses =
                  £159,492.31
                </li>
                <li>Taxes (26.5% corporate tax rate): £42,265.46</li>
                <li>Net Income After Taxes: £117,226.85</li>
              </p>
              <p>
                <p>Year 4:</p>
                <li>Sales Revenue: £198,375 * 15% = £228,131.25</li>
                <li>
                  Franchise Sales: 9 franchises * £10,000/franchise = £90,000
                </li>
                <li>Total Revenue: £318,131.25</li>
                <li>
                  COGS (Assuming a 2% rise due to inflation or other factors):
                  41% of Sales Revenue = £93,533.81
                </li>
                <li>Gross Profit: Total Revenue - COGS = £224,597.44</li>
                <li>
                  Operating Expenses (Assuming a 5% increase due to business
                  expansion): 17.25% of Gross Profit = £38,793.33
                </li>
                <li>
                  Net Income Before Taxes: Gross Profit - Operating Expenses =
                  £185,804.11
                </li>
                <li>Taxes (26.5% corporate tax rate): £49,238.08</li>
                <li>Net Income After Taxes: £136,566.03</li>
              </p>
              <p>
                <p>Year 5:</p>
                <li>Sales Revenue: £228,131.25 * 15% = £262,350.94</li>
                <li>
                  Franchise Sales: 11 franchises * £10,000/franchise = £110,000
                </li>
                <li>Total Revenue: £372,350.94</li>
                <li>
                  COGS (Assuming a 2% rise due to inflation or other factors):
                  43% of Sales Revenue = £112,810.90
                </li>
                <li>Gross Profit: Total Revenue - COGS = £259,540.04</li>
                <li>
                  Operating Expenses (Assuming a 5% increase due to business
                  expansion): 18% of Gross Profit = £46,717.20
                </li>
                <li>
                  Net Income Before Taxes: Gross Profit - Operating Expenses =
                  £212,823.20
                </li>
                <li>Taxes (26.5% corporate tax rate): £56,398.14</li>
                <li>Net Income After Taxes: £156,425.06</li>
              </p>
            </Tab>
            <Tab eventKey="appendix" title="Appendix">
              <p>
                <h6>Appendix A: Equipment and Supplies</h6>
                <li>Professional grade car cleaning detergents and waxes</li>
                <li>Microfiber towels and sponges</li>
                <li>Electric pressure washer</li>
                <li>Portable water tank</li>
                <li>Extension cords and power strips</li>
                <li>Compound clay and color, polish and wax kits</li>
                <li>Detailing brushes and tyre shine</li>
                <li>Work uniforms and name tags</li>
                <li>Business cards and brochures</li>
                <li>Website and social media assets</li>
                <li>Insurance documents</li>
                <li>Vehicle signage and wraps</li>
                <li>Mobile phone and tablet for scheduling and invoicing</li>
                <li>GPS tracking device for employee management</li>
              </p>
              <p>
                <h6>Appendix B: Employee Training Manual</h6>
                <li>Introduction to the company and mission statement</li>
                <li>Health and safety protocols</li>
                <li>Detailing techniques and best practices</li>
                <li>Customer service standards</li>
                <li>Equipment operation and maintenance</li>
                <li>Time management and scheduling</li>
                <li>Sales and upselling techniques</li>
                <li>Professionalism and dress code</li>
                <li>Social media and online reputation management</li>
              </p>
              <p>
                <h6>Appendix C: Franchise Agreement</h6>
                <li>Franchisee responsibilities and expectations</li>
                <li>Royalty and fee structure</li>
                <li>Territory and exclusivity rights</li>
                <li>Training and support provided by the franchisor</li>
                <li>Marketing and advertising guidelines</li>
                <li>Operations and management standards</li>
                <li>Renewal and termination provisions</li>
                <li>Dispute resolution process</li>
              </p>
              <p>
                <h6>Appendix D: Market Research and Analysis</h6>
                <li>Industry overview and trends</li>
                <li>Target market demographics and psychographics</li>
                <li>Competitor analysis</li>
                <li>SWOT analysis</li>
                <li>Sales and revenue projections</li>
                <li>Marketing and advertising strategies</li>
                <li>Consumer feedback and surveys</li>
              </p>
              <p>
                <h6>Appendix E: Legal Documents</h6>
                <li>Incorporation papers and articles of association</li>
                <li>Operating agreement or partnership agreement</li>
                <li>Unique Taxpayer Reference (UTR)</li>
                <li>Business licenses and permits</li>
                <li>Insurance policies</li>
                <li>Trademarks and copyrights</li>
                <li>Non-disclosure and non-compete agreements</li>
                <li>Lease or rental agreements</li>
                <li>Loan and financing documents</li>
              </p>
            </Tab>
          </Tabs>
        </div>
        <div className="column is-one-fifth fadeLeftMini"></div>
      </div>
    </section>
  </>
);
