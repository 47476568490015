import React from 'react'
import './components/navigationbar.css'
import { Tab, Tabs } from 'react-bootstrap'

export const SwotPlan = () => (
  <>
    <div className="hero">
      <h1>SWOT Analysis</h1>
    </div>
    <section className="hero is-fullheight mb-5">
      <div className="columns">
        <div className="column is-one-fifth fadeLeftMini"></div>
        <div className="column is-vcentered">
          <Tabs defaultActiveKey="first" id="uncontrolled-tab-example">
            <Tab eventKey="first" title="1 Year">
              <p>
                <h6>Strengths:</h6>

                <li>
                Proprietary eco-friendly cleaning solutions that differentiate us from competitors.
                </li>
                <li>
                Mobile service model that provides convenience and flexibility for customers.
                </li>
                <li>Experienced and skilled team of technicians ensuring high-quality service.</li>
                <li>Strong brand and reputation in the community due to commitment to customer satisfaction.</li>
                <li>
                Solid partnerships with suppliers and equipment providers ensuring reliable service delivery.
                </li>
              </p>
              <p>
                <h6>Weaknesses:</h6>

                <li>
                Initial limited service area, which could restrict our customer base.
                </li>
                <li>
                Dependence on a single revenue stream (car cleaning services) could pose a risk if demand decreases.
                </li>
                <li>
                Limited financial resources for aggressive marketing and swift expansion.
                </li>
                <li>
                Challenges in maintaining consistent service quality across franchisees due to limited control.
                </li>
              </p>
              <p>
                <h6>Opportunities:</h6>

                <li>Rising consumer demand for eco-friendly cleaning solutions.</li>
                <li>
                Potential to diversify service offerings in the future (e.g., detailing for boats, RVs, aircraft).
                </li>
                <li>Opportunity for national and international franchise expansion as the brand grows.</li>
                <li>
                Potential for strategic partnerships and collaborations with other businesses in the industry or complementary industries.
                </li>
              </p>
              <p>
                <h6>Threats:</h6>

                <li>High competition in the car cleaning industry could limit market share.</li>
                <li>
                Economic downturns or recessions may lead to decreased consumer spending on non-essential services like car detailing.
                </li>
                <li>
                New regulations or laws that could increase operating costs or impact the business model.
                </li>
                <li>
                Adverse weather conditions, such as heavy rain or snow, could interrupt service delivery and affect revenue.
                </li>
              </p>
            </Tab>
            <Tab eventKey="fifth" title="5 Years">
              <p>
                <h6>Strengths:</h6>

                <li>
                Unique mobile car cleaning service, providing unmatched convenience for customers.
                </li>
                <li>
                Eco-friendly cleaning solutions, appealing to a growing demographic of environmentally conscious consumers.
                </li>
                <li>
                Established branding and reputation, fortified through successful national and international franchise expansion.
                </li>
                <li>
                A team of highly skilled and experienced technicians ensuring consistently high-quality service.
                </li>
              </p>
              <p>
                <h6>Weaknesses:</h6>

                <li>
                Dependence on franchisees for business growth, which could lead to variability in service quality.
                </li>
                <li>Limited service area, as the company is mobile-based and might not be able to service remote areas.</li>
                <li>
                Potential for intense competition from both established car wash businesses and emerging mobile car cleaning services.
                </li>
              </p>
              <p>
                <h6>Opportunities:</h6>

                <li>Further expansion into new domestic and international markets through franchising.</li>
                <li>
                Investment in the development of new and innovative cleaning methods and techniques to stay ahead of competition.
                </li>
                <li>
                Opportunities for strategic partnerships and collaborations with other companies in the automotive industry.
                </li>
                <li>
                Capitalizing on increasing consumer demand for eco-friendly products and services.
                </li>
              </p>
              <p>
                <h6>Threats:</h6>

                <li>
                Economic downturns leading to potential decrease in consumer spending on non-essential services such as car detailing.
                </li>
                <li>
                Technological advancements in car cleaning methods and equipment could require additional investment to stay competitive.
                </li>
                <li>
                Potential new government regulations and laws that may increase operating costs or affect the business model.
                </li>
                <li>
                Increasing competition not just from traditional brick and mortar car wash businesses but also from new and innovative mobile car cleaning companies.
                </li>
              </p>
            </Tab>
            <Tab eventKey="tenth" title="10 Years">
              <p>
                <h6>Strengths:</h6>

                <li>
                Established brand recognition and solid reputation as a leading provider of high-quality, eco-friendly car cleaning and detailing services.
                </li>
                <li>
                Proven franchising model that supports rapid expansion and scalability across multiple markets.
                </li>
                <li>
                A network of experienced and skilled technicians trained in the latest cleaning techniques and technologies.
                </li>
                <li>
                Strong relationships with suppliers and vendors enabling competitive pricing and steady supply of equipment and cleaning solutions.
                </li>
              </p>
              <p>
                <h6>Weaknesses:</h6>

                <li>Limited geographic reach and market penetration, restricting access to potential customers.</li>
                <li>
                Over-reliance on a small number of key franchisees for revenue, increasing business risk.
                </li>
                <li>
                Limited diversification in services and product offerings, reducing potential income streams.
                </li>
                <li>
                Challenges in maintaining consistent quality of services provided across all franchisees.
                </li>
                <li>
                High costs associated with maintaining, updating, and innovating equipment and cleaning supplies.
                </li>
              </p>
              <p>
                <h6>Opportunities:</h6>

                <li>
                Growing global demand for eco-friendly car cleaning and detailing services, creating potential for business expansion.
                </li>
                <li>
                Opportunities to further expand into new geographic markets and diversify service offerings.
                </li>
                <li>
                Leveraging technology to streamline operations, enhance customer experience, and provide innovative solutions.
                </li>
                <li>
                Potential to establish strategic partnerships and alliances with complementary businesses, increasing market presence and customer reach.
                </li>
              </p>
              <p>
                <h6>Threats:</h6>

                <li>
                Intense competition from local, regional, and global car cleaning and detailing companies, pressuring market share and pricing power.
                </li>
                <li>
                Economic downturns or changes in consumer behavior negatively impacting spending on non-essential services.
                </li>
                <li>Increasing regulatory pressures and compliance costs, potentially affecting operational costs and business model.</li>
                <li>
                Dependence on a small number of key franchisees for revenue, posing a business continuity risk.
                </li>
                <li>Risk of franchisees opening competing businesses, leading to potential market dilution and brand confusion.</li>
              </p>
            </Tab>
          </Tabs>
        </div>
        <div className="column is-one-fifth fadeLeftMini"></div>
      </div>
    </section>
  </>
)
