import React from 'react'
import './components/navigationbar.css'
import { Tab, Tabs } from 'react-bootstrap'

export const ExecutiveAction = () => (
  <>
    <div className="hero">
      <h1>Executive Action</h1>
    </div>
    <section className="hero is-fullheight mb-5">
      <div className="columns">
        <div className="column is-one-fifth fadeLeftMini"></div>
        <div className="column is-vcentered">
          <Tabs defaultActiveKey="first" id="uncontrolled-tab-example">
            <Tab eventKey="first" title="First Year">
              <p>
                <h6>First Quarter: </h6>

                <li>
                Secure necessary funding through investors and bank loans, presenting a detailed business plan.
                </li>
                <li>
                Incorporate the company, register for all necessary licenses and permits, and ensure compliance with local regulations.
                </li>
                <li>
                Purchase essential equipment and supplies, including mobile detailing units and eco-friendly cleaning supplies.
                </li>
                <li>
                Develop and launch a professional website and active social media platforms to promote services and engage with potential customers.
                </li>
                <li>Begin the recruitment process and training program for franchisees, focusing on customer service and quality assurance.</li>
              </p>
              <p>
                <h6>Second Quarter:</h6>

                <li>
                  Launch <span className="fire">mobilemintcarcare.com</span> in a test market, leveraging customer feedback and franchisee insights to refine the service model.
                </li>
                <li>
                Use data-driven feedback to continuously improve service offerings and operational processes.
                </li>
                <li>
                Gradually roll out services to additional markets, particularly high-population areas with significant demand.
                </li>
                <li>Continue the recruitment and training of franchisees, ensuring they align with company values and quality standards.</li>
                <li>
                Initiate targeted marketing and advertising campaigns to increase brand awareness and attract potential customers.
                </li>
              </p>
              <p>
                <h6>Third Quarter:</h6>

                <li>
                Expand services into additional urban markets with high demand for mobile detailing services, balancing growth with maintaining service quality.
                </li>
                <li>
                Continue to provide support, training, and resources to franchisees to ensure consistent high-quality service delivery.
                </li>
                <li>
                Regularly monitor and analyze market trends, customer feedback, and operational data to identify areas for improvement and growth opportunities.
                </li>
                <li>Begin researching and planning for international franchising opportunities, considering cultural differences and local market conditions.</li>
              </p>
              <p>
                <h6>Fourth Quarter:</h6>

                <li>
                Continue expanding services into new markets, prioritizing urban areas with a high demand for mobile detailing services.
                </li>
                <li>
                Keep a close watch on market trends, customer feedback, and competition to adapt strategies and services as necessary.
                </li>
                <li>
                Start implementing long-term growth strategies, such as developing new service offerings, exploring partnership opportunities, and launching an affiliate program.
                </li>
                <li>
                Continue investigating and preparing for international franchising opportunities, including legal and financial implications.
                </li>
              </p>
            </Tab>
            <Tab eventKey="fifth" title="From First Year to Fifth Year">
              <p>
                <h6>Year 1:</h6>

                <li>
                  Develop and launch{' '}
                  <span className="fire">mobilemintcarcare.com</span> website, ensuring it is user-friendly and showcases the full range of services.
                </li>
                <li>
                Implement a robust advertising strategy on social media and online platforms to raise brand awareness and attract initial customers.
                </li>
                <li>
                Recruit and train a skilled team to provide high-quality car cleaning services, emphasizing customer service and attention to detail.
                </li>
                <li>
                Establish strategic partnerships with local car dealerships and car rental companies for steady business and referrals.
                </li>
                <li>
                Onboard the first franchisees, providing comprehensive training and ongoing support to ensure their successful operation of <span className="fire">mobilemintcarcare.com</span>{' '}
                franchises.
                </li>
              </p>
              <p>
                <h6>Year 2:</h6>

                <li>
                Expand the service area to cover multiple cities within the UK, based on market demand and logistical feasibility.
                </li>
                <li>
                Develop and introduce new services, such as advanced detailing and paint protection, to differentiate from competitors.
                </li>
                <li>
                Scale up advertising and promotional efforts to attract new customers, using customer data to optimize targeting.
                </li>
                <li>
                Onboard additional franchisees, focusing on their ongoing support and development to foster business growth.
                </li>
                <li>
                Continually refine operations and processes, leveraging technology and feedback, to enhance efficiency and profitability.
                </li>
              </p>
              <p>
                <h6>Year 3:</h6>

                <li>
                Extend the service area to cover multiple countries within Europe, considering local regulations, market dynamics, and logistics.
                </li>
                <li>
                Develop and launch a customer-friendly mobile app for easy scheduling and payment of services.
                </li>
                <li>
                Increase the advertising budget to reach a wider audience across Europe, optimizing the strategy based on the unique needs of each market.
                </li>
                <li>
                Bring on more franchisees, providing them with the necessary training and support for successful operations in their respective markets.
                </li>
                <li>
                Keep focusing on operational efficiency and profitability, implementing best practices from industry and franchisee feedback.
                </li>
              </p>
              <p>
                <h6>Year 4:</h6>

                <li>
                Expand the service area to multiple countries worldwide, ensuring compliance with international trade laws and regulations.
                </li>
                <li>
                Develop and introduce additional premium services, such as ceramic coating, Paint Protection Film (PPF), and window tinting, to meet diverse customer needs.
                </li>
                <li>
                Scale up advertising and promotional efforts, leveraging a mix of global and local channels, to attract new customers.
                </li>
                <li>
                Onboard more franchisees, ensuring they are equipped with the skills and resources necessary to succeed in their local markets.
                </li>
                <li>
                Continually enhance operations and processes, focusing on scalable solutions and best practices for global operations.
                </li>
              </p>
              <p>
                <h6>Year 5:</h6>

                <li>
                Continue worldwide expansion, establishing a strong presence in key markets.
                </li>
                <li>
                Develop and launch additional specialized services, such as wheel and rim repair, paintless dent removal, to further differentiate the brand.
                </li>
                <li>
                Enhance advertising and promotional efforts, employing innovative strategies to engage new customers.
                </li>
                <li>
                Onboard even more franchisees, strengthening the global franchise network through continuous training and support.
                </li>
                <li>
                Keep improving operations and processes to increase efficiency and profitability, with a focus on sustainable practices.
                </li>
                <li>
                Conduct a thorough review of the company's progress, making necessary adjustments to ensure sustainable growth and long-term success.
                </li>
              </p>
            </Tab>
            <Tab eventKey="tenth" title="From First Year to Tenth Year">
              <p>
                <h6>Year 1:</h6>

                <li>
                  Launch <span className="fire">mobilemintcarcare.com</span> in a pilot city with five franchise locations.
                </li>
                <li>Develop and implement a training program for franchisees.</li>
                <li>Establish relationships with suppliers and vendors.</li>
                <li>Begin marketing and advertising efforts.</li>
                <li>
                Monitor and evaluate the performance of the pilot locations.
                </li>
              </p>
              <p>
                <h6>Year 2-3:</h6>
                <li>
                Expand to an additional 15 franchise locations in the pilot city.
                </li>
                <li>
                Begin franchise sales efforts and start selling franchises in other cities.
                </li>
                <li>
                Continue to develop and improve the training program for franchisees.
                </li>
                <li>Increase marketing and advertising efforts.</li>
                <li>
                Monitor and evaluate the performance of all franchise locations.
                </li>
              </p>
              <p>
                <h6>Year 4-6:</h6>
                <li>
                Expand to 50 franchise locations in multiple cities across the country.
                </li>
                <li>
                Continue franchise sales efforts and begin selling franchises internationally.
                </li>
                <li>Develop and implement a customer loyalty program.</li>
                <li>Develop and implement an eco-friendly initiative.</li>
                <li>
                Monitor and evaluate the performance of all franchise locations.
                </li>
              </p>
              <p>
                <h6>Year 7-9:</h6>
                <li>Expand to 100 franchise locations internationally.</li>
                <li>
                Continue franchise sales efforts, focusing on developing new markets.
                </li>
                <li>
                Develop and implement technology to improve the customer experience and streamline operations.
                </li>
                <li>
                Monitor and evaluate the performance of all franchise locations.
                </li>
                <li>
                Continuously improve and update the training program for franchisees.
                </li>
              </p>
              <p>
                <h6>Year 10:</h6>

                <li>Achieve a total of 200 franchise locations globally.</li>
                <li>Re-evaluate and adjust strategies as needed.</li>
                <li>
                Continuously monitor and evaluate the performance of all franchise locations.
                </li>
                <li>
                Continuously improve and update the training program for franchisees.
                </li>
                <li>
                Focus on maintaining and increasing revenue and profitability.
                </li>
              </p>
            </Tab>
          </Tabs>
        </div>
        <div className="column is-one-fifth fadeLeftMini"></div>
      </div>
    </section>
  </>
)
