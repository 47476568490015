import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Home } from "./Home";
import { Test } from "./Test";
import { NavigationBar } from "./components/NavigationBar";
import { Footer } from "./components/Footer";
import { BusinessPlan } from "./BusinessPlan";
import { ActionPlan } from "./ActionPlan";
import { SwotAnalysis } from "./SwotAnalysis";
import { BenefitsAdvantages } from "./BenefitsAdvantages";
import { Contact } from "./Contact.js";
import { NoMatch } from "./NoMatch";
import { BusinessExecutive } from "./BusinessExecutive";
import { ExecutiveAction } from "./ExecutiveAction";
import { SwotPlan } from "./SwotPlan";
import { WhitePaper } from "./WhitePaper";
import PasswordProtected from "./components/PasswordProtected";

const App: React.FC = () => {
  const password = "mintax";

  return (
    <>
      <Router>
        <PasswordProtected password={password}>
          <NavigationBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Test" element={<Test />} />
            <Route path="/BusinessPlan" element={<BusinessPlan />} />
            <Route path="/BusinessExecutive" element={<BusinessExecutive />} />
            <Route path="/ExecutiveAction" element={<ExecutiveAction />} />
            <Route path="/SwotPlan" element={<SwotPlan />} />
            <Route path="/ActionPlan" element={<ActionPlan />} />
            <Route path="/SwotAnalysis" element={<SwotAnalysis />} />
            <Route path="/WhitePaper" element={<WhitePaper />} />
            <Route
              path="/BenefitsAdvantages"
              element={<BenefitsAdvantages />}
            />
            <Route path="/Contact" element={<Contact />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
          <Footer />
        </PasswordProtected>
      </Router>
    </>
  );
};

export default App;
