import React from "react";

export const SwotAnalysis = () => (
  <>
    <div className="hero">
      <h1>SWOT Analysis</h1>
    </div>
    <section className="hero is-fullheight mb-5">
      <div className="columns">
        <div className="column is-one-quarter fadeLeftMini"></div>
        <div className="column fadeLeftMini">
          <p>
            The following SWOT analysis is a strategic tool to evaluate the
            strengths, weaknesses, opportunities, and threats of our mobile car
            cleaning and polishing service.
          </p>
          <p>
            This analysis provides a deep understanding of the internal and
            external factors that could impact our company's growth and success.
          </p>
          <p>
            By identifying our strengths and weaknesses, we can make strategic
            decisions to capitalize on opportunities and mitigate potential
            threats.
          </p>
          <p>
            This analysis will be conducted regularly to stay abreast of market
            changes and make informed decisions to enhance our service's
            performance.
          </p>
          <button
            className="button is-pulled-right btn"
            title="Swot Analysis"
            onClick={(event) => (window.location.href = "/SwotPlan")}
          >
            <span>Swot Analysis</span>
          </button>
        </div>
        <div className="column is-one-quarter fadeLeftMini"></div>
      </div>
    </section>
  </>
);
