import React from "react";

export const Contact = () => {
  return (
    <>
      <section className="hero is-fullheight mb-5">
        <div className="hero">
          <h1>About & Contact</h1>
        </div>
        <div className="columns">
          <div className="column fadeLeftMini">
            <h2>
              Welcome to Mobile Mint Car Care - Your Go-To Solution for Auto
              Detailing
            </h2>
            <p>
              Welcome to the Contact page of Mobile Mint Car Care, your
              destination for comprehensive car care needs. We offer top-tier
              car detailing services, including complete valets, compound,
              polish, wax, and quick washes. Our seasoned professionals ensure
              your car remains in impeccable condition.
            </p>
          </div>
          <div className="column">
            <h2>
              Get in Touch with Our Team for Unparalleled Service and Support
            </h2>
            <p>
              If you have any inquiries or wish to schedule an appointment,
              please contact us via phone, email. We strive to respond promptly.
              Our dedicated customer service team is always ready to assist and
              address any concerns, ensuring you are thoroughly satisfied with
              our services.
            </p>
          </div>
          <div className="column fadeRightMini">
            <h2>
              Engage with Mobile Mint Car Care for All Your Auto Detailing
              Requirements
            </h2>
            <p>
              In addition to our core services, we offer complimentary tyre
              pressure checks and screen wash top-ups. These additional touches
              contribute to the overall maintenance of your vehicle. We
              continuously seek ways to enhance our services to meet your
              evolving needs. We look forward to providing you with an unrivaled
              car care experience.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};
