import React from "react";
import "./navigationbar.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

export const NavigationBar = () => (
  <>
    <Navbar className="color-nav" expand="lg">
      <Container fluid>
        <Navbar.Brand className="navItem t2" href="/">
          <span className="fire">MMCC</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link className="navItem t1" href="/BusinessPlan">
              Business Plan
            </Nav.Link>
            <Nav.Link className="navItem t1" href="/ActionPlan">
              Action Plan
            </Nav.Link>
            <Nav.Link className="navItem t1" href="/SwotAnalysis">
              Swot Analysis
            </Nav.Link>
            <Nav.Link className="navItem t1" href="/BenefitsAdvantages">
              Benefits & Advantages
            </Nav.Link>
            <Nav.Link className="navItem t1" href="/WhitePaper">
              White Paper
            </Nav.Link>
            <Nav.Link className="navItem t1" href="/Contact">
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </>
);
