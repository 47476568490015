import logo from "./mmcc.svg";
import whatsapp from "./whatsapp.svg";
import instagram from "./instagram.svg";
import twitter from "./twitter.svg";
import linkedin from "./linkedin.svg";
import facebook from "./facebook.svg";
import phone from "./phone.svg";
import youtube from "./youtube.svg";
import details from "./details.svg";
import list from "./list.svg";
import tolkien from "./tolkien.svg";

// eslint-disable-next-line
export default {
  logo,
  whatsapp,
  instagram,
  linkedin,
  twitter,
  facebook,
  phone,
  youtube,
  details,
  list,
  tolkien,
};
