import React from "react";

export const ActionPlan = () => (
  <>
    <div className="hero">
      <h1>Action Plan</h1>
    </div>
    <section className="hero is-fullheight mb-5">
      <div className="columns">
        <div className="column is-one-quarter fadeLeftMini"></div>
        <div className="column fadeLeftMini">
          <p>
            This strategic plan outlines our systematic approach to establishing
            and expanding our mobile car cleaning and polishing service. It
            details our mission, objectives, strategies, and tactics for
            achieving our goals over the next decade.
          </p>
          <p>
            We will begin by launching our service and expanding our service
            area, followed by introducing additional services such as tyre
            pressure checks and screen wash top-ups. Our primary focus will be
            on building our brand, increasing customer awareness, and
            implementing automation to enhance efficiency.
          </p>
          <p>
            Our ultimate goal is to expand globally by establishing franchises
            in various countries while consistently delivering exceptional
            service. This strategic plan will be regularly reviewed and updated
            to ensure it aligns with the company's evolving goals and
            objectives.
          </p>{" "}
          <button
            className="button is-pulled-right btn"
            title="Action Plan"
            onClick={(event) => (window.location.href = "/ExecutiveAction")}
          >
            <span>Action Plan</span>
          </button>
        </div>
        <div className="column is-one-quarter fadeLeftMini"></div>
      </div>
    </section>
  </>
);
